.App {
  text-align: center;
}

.LandingPage-Logo {
  margin-top: 20px;
  justify-content: center;
}

.App-header {
  margin-top: 0px;
  position: absolute;
  width: 100%;
  background-color: #FAE0E6;
  height: 68px;
}